<template>
  <div class="">
     <Button
           
           btnVariant="yellow"
            @click="openShopifyProductModal()">
            import from Big Commerce
          </Button>
   

   

    <!-- Shopify Modal -->
    <ModalVue
      ref="Modal"
      id="copyShopifyProducts"
      title="Add Collections from Big Commerce"
      size="lg">
      <div class="my-2" :class="!isLoadingSync ? 'd-none' : 'd-block'">
        <ProgressbarVue ref="progressbar"></ProgressbarVue>
        <span
          class="text-success text-center"
          style="font-size: 12px; text-align: center">
          Collections syncing soon, continue tasks uninterrupted.
        </span>
      </div>
      <FilterBox
        :search="true"
        placeholder="Search Collections..."
        @input="searchProductQueryHandle($event)"></FilterBox>

    
      <div class="loader px-2">
        <div
          class="d-flex py-2"
          :class="[ 'justify-content-start']">
          <div
            class="d-flex align-items-center"
            v-if="isLoadingShopifyCollections">
            <b-spinner small style="color: #4d1b7e"></b-spinner>
            <span class="ml-2">Loading Collections</span>
          </div>
         
        </div>
      </div>
      <b-skeleton-table
        v-if="isLoadingShopifyProductFirstTime"
        :rows="5"
        :columns="3"
        :table-props="{ bordered: true, striped: true }"></b-skeleton-table>
      <div class="data-table" v-else-if="shopifyProducts.length">
        <div
          class=""
          style="max-height: 55vh; min-height: 55vh; overflow: auto">
          <b-table
            head-class="text-center"
            class="leadTable"
            :stickyColumn="true"
            hover
            :fields="shopifyProductsFields"
            :items="shopifyProducts">
            <template #head(checkbox)>
              <b-form-checkbox
                size="lg"
                :disabled="
                  isLoadingShopifyCollections ||
                  isLoadingSelectedSyncProducts ||
                  isLoadingSync
                "
                v-model="shopifySelectAllCheck"
                @change="ProductSelectAllToggle($event)"
                class="checkbox selectAll"></b-form-checkbox>
            </template>
            <template #cell(checkbox)="data">
              <b-form-checkbox
                :value="data.item.id"
                v-model="selectedCollections"
                :disabled="
                  isLoadingShopifyCollections ||
                  isLoadingSelectedSyncProducts ||
                  isLoadingSync
                "
                size="lg"
                class="checkbox"></b-form-checkbox>
            </template>
             <template #cell(title)="data">
            
             {{ data.item.name }}
            </template>
          </b-table>
        </div>
      </div>
      <template v-else>

<div class="d-flex justify-content-center align-items-center" style="height: 50vh;" >

<EmptyTable >
<template #title>
No collection found
</template>
<template #description>
Time to fill your shelves or refine your search. Remember, your results will appear right here.
</template>
</EmptyTable>
</div>

</template>
      <div class="d-flex justify-content-between">
        <ShopifyPagination
          :hasNext="pageInfo.hasNext!=null||isLoadingShopifyCollections"
          @onNext="
            () => {
              pageInfo.currentPage++;
              getProductsFromShopify();
            }
          "
          :hasPrevious="pageInfo.currentPage>1||isLoadingShopifyCollections"
          @onPrevious="
            () => {
              pageInfo.currentPage--;
              getProductsFromShopify();
            }
          "></ShopifyPagination>
           <div
            class="d-flex py-2 py-lg-0 w-100 flex-row align-items-center justify-content-lg-end"
           >
            <Button
            btnVariant=""
              
              @click="syncSelected()"
              :disabled="
                isLoadingSync ||
                isLoadingShopifyCollections ||
                isLoadingSelectedSyncProducts
              "
              :isLoading="isLoadingSelectedSyncProducts"
              style="border-radius: 4px !important"
              v-if="selectedCollections.length"
              >Sync Selected <b-badge variant="light">{{ shopifyProducts.length }}</b-badge></Button
            >
            <Button
            btnVariant="yellow"
              
              @click="syncSelected('all')"
              :disabled="
                isLoadingSync ||
                isLoadingShopifyCollections ||
                isLoadingSelectedSyncProducts
              "
              :isLoading="isLoadingSelectedSyncProducts"
              style="border-radius: 4px !important"
              v-else
              >Sync All</Button
            >
           
          </div>
      </div>
    </ModalVue>
  
      
  </div>
 
</template>
<script>
import { mapGetters } from "vuex";
import { debounce } from "lodash";
import EmptyTable from "../../Layout/EmptyTable.vue";
import ShopifyPagination from "../../Layout/ShopifyPagination.vue";
import FilterBox from "../../Layout/FilterBox.vue";

import ModalVue from "../../Layout/Modal.vue";
import ProgressbarVue from "../../Layout/Progressbar.vue";
import axios from "axios";
import Button from '../../Layout/Button.vue';



export default {
  components: {
    FilterBox,
    EmptyTable,
    ShopifyPagination,
    ModalVue,
    Button,
    ProgressbarVue,
   
   
  },
  data() {
    return {
      isLoadAddProduct:false,
      selectAllQuizellProducts: false,
      selectedQuizellProducts: [],
      searchProductText: "",
      currentPage: 1,
      perPage: 10,
      totalRows: 10,
      pageInfo: {
        currentPage:1,
        perPage:25,
        hasNext:null,
        name:''
      },
      isLoadingShopifyCollections: false,
      isLoadingShopifyProductFirstTime: false,
      shopifySelectAllCheck: false,
      selectedCollections: [],
    
      shopifyProducts: [],
      shopifyProductsFields: [
        {
          key: "checkbox",
          label: "",
          tdClass: "align-middle data-Table cursor-pointer",
          thClass: "bg-table-heading",
        },
        {
          key: "title",
          label: "Title",
          tdClass: "align-middle data-Table cursor-pointer",
          thClass: "bg-table-heading",
        },

        
      ],
      items: [],
      isLoadingFirstTime: true,
      isLoadingSelectedSyncProducts: false,
      isLoadingDeleteProduct: false,
      isLoadingSync: false,
      isLoading: false,
      productProducts:[],
      queryObject: {
  "productsFirst": 50,
  "productsLast": null,
  "before": null,
  "after": null,
  "sortKey": "TITLE",
  "query": ""
}




    
    };
  },
  watch: {
  
 
  
    allProducts:{
      handler({selected,all}){
       this.selectAllQuizellProducts=all.every(collections=>selected.includes(collections))
      },
      deep:true
    },
   
  },
  computed: {
    ...mapGetters(["getNavbarData"]),
    allProducts(){
      return {selected:this.selectedCollections,all:this.shopifyProducts}
    },
  
    shopifyAllProducts() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.shopifyProducts.map((collections) => collections.id);
    },
  },
  methods: {
   
 
  
   
   
    openShopifyProductModal() {
      
    
        this.isLoadingShopifyProductFirstTime = true;
      this.$refs.Modal.open();
      this.selectedCollections=[];
      this.getProductsFromShopify();
    },
    async syncSelected(type='') {
      this.isLoadingSelectedSyncProducts = true;
      let selectedCollections=null
      if(type==='')
      selectedCollections = this.selectedCollections
      await axios
        .post("/api/bigcommerce/categories/import", {
          selectedIds: selectedCollections,
        })
        .then(() => {
          this.$refs.Modal.close();
          // this.getProducts("/collections/list");
          this.getProductsFromShopify()
          this.$toasted.show("Collections sync successfully", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        })
        .catch((e) => {
          this.$toasted.show("Error occurred while syncing products", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          console.log(e);
        });
      this.isLoadingSelectedSyncProducts = false;
    },
    searchProductQueryHandle(e) {
     
      this.pageInfo.name = e;
      this.handleSearchDebounced();
    },
    ProductSelectAllToggle() {
      this.selectedCollections = [];
      if (this.shopifySelectAllCheck) {
        this.selectedCollections = this.shopifyAllProducts;
      }
    },
 
    async getProductsFromShopify() {
      this.isLoadingShopifyCollections = true;
      const payload={
        per_page:this.pageInfo.perPage,
        page:this.pageInfo.currentPage,
        name:this.pageInfo.name
      }
     
      await axios
        .get("/api/bigcommerce/categories",{params:payload})
        .then((response) => {
         
          if (response.status) {
              let {next_page} =  response.data.data.collections.meta
            this.pageInfo.hasNext =next_page;
           
            this.shopifyProducts=response.data.data.collections.data
          
          } else {
            this.$toasted.show("Error occurred getting products. ", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
          }
          
        })
        .catch((e) => {
          console.log(e);
        });
      this.isLoadingShopifyCollections = false;
      this.isLoadingShopifyProductFirstTime = false;
    },
 

    handleSearchDebounced: debounce(function () {
      this.getProductsFromShopify();
    }, 500), // 500ms debounce time, adjust as needed
   
  },
  mounted() {
   
  },
};
</script>
<style scoped>
.shopify-btn:active {
  background-color: #2e2f2f;
  color: #ffffff;
  border-color: #2e2f2f;
}
.shopify-btn {
  appearance: none;
  cursor: pointer;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #030303;
  color: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;

  font-size: 14px;

  transition: 150ms ease;
  transition-property: background-color, border-color;
  display: inline-block;

  border-radius: 6px;
}
.checkbox >>> .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: var(--secondary-color);
  background-color: var(--secondary-color) !important;
}
.checkbox >>> .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem var(--secondary-color);
}
.leadTable >>> thead {
  border-radius: 6px;
  color: black;
  background: rgba(179, 175, 182, 0.07);
  border: none;
}
.leadTable >>> thead tr {
  border-radius: 10px;
}
.leadTable >>> thead th {
  border: none;
  vertical-align: middle !important;
}
.loader {
  min-height: 40px;
}
.collections-image {
  height: 40px;
  width: 40px;
  /* border: 4px solid rgb(255, 255, 255); */
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
  background-size: cover;
  /* padding: 0.25rem; */
  /* background-color: #fff; */
}
.customPagination >>> li > button {
  color: #4d1b7e;
}

.customPagination >>> li.active > button {
  color: white;
  background-color: #4d1b7e !important;
  border-color: #4d1b7e;
}
.customPagination >>> li > button:hover {
  color: white;
  background-color: #6b4690 !important;
  border-color: #4d1b7e;
}
#collections-detail >>> button {
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
}
#collections-detail >>> button::after {
  display: none;
}
#collections-detail >>> ul {
  background: #ffffff;
  box-shadow: 0px 10px 20px -3px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}
#collections-detail >>> li.topbarLink {
  color: #344054;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
}
#collections-detail >>> li a:active {
  color: #fff;
  text-decoration: none;
  background-color: #4d1b7e;
}
</style>
